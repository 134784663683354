<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <!-- <span class="d-none d-lg-block mr-2 font-weight-bold">
        Hola Gabriel Nadal!
      </span> -->
      <b-avatar size="35" variant="light-primary" />
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="$router.push({ name: 'foravila-account' })"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-2" />
      <span>{{ $t("My account") }}</span>
    </b-dropdown-item>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-2" />
      <span>{{ $t("Log out") }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BAvatar } from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useBasicAuth from "@/auth/basic/useBasicAuth";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar
  },
  setup() {
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage
    };
  },
  methods: {
    logout() {
      useBasicAuth.logout();

      // Remove userData from localStorage
      localStorage.removeItem("foravilaGuestsUserData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "foravila-login" });
    }
  }
};
</script>
