<template>
  <layout-vertical>
    <template #navbar="slotProps">
      <app-navbar-vertical-layout
        :toggle-vertical-menu-active="slotProps.toggleVerticalMenuActive"
      />
    </template>

    <template #vertical-menu-header>
      <b-link class="navbar-brand" :to="{ name: 'foravila-home' }">
        <span class="brand-logo">
          <foravila-logo />
        </span>
      </b-link>
    </template>

    <template #breadcrumb>
      <app-breadcrumb class="mb-4" />
    </template>

    <template #footer>
      <app-footer />
    </template>

    <router-view />
  </layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import ForavilaLogo from "@/layouts/foravila/components/ForavilaLogo.vue";
import { BLink } from "bootstrap-vue";
import AppNavbarVerticalLayout from "@/layouts/foravila/components/app-navbar/AppNavbarVerticalLayout.vue";
import AppFooter from "@/layouts/foravila/components/AppFooter.vue";
import AppBreadcrumb from "@/layouts/foravila/components/AppBreadcrumb.vue";

export default {
  components: {
    LayoutVertical,
    ForavilaLogo,
    BLink,
    AppNavbarVerticalLayout,
    AppFooter,
    AppBreadcrumb
  }
};
</script>
